<template>
  <v-row no-gutters>
    <v-container class="d-flex justify-center">
      <v-card
        color="white"
        width="100%"
        max-width="600"
        rounded="lg"
        elevation="5"
        :loading="loading"
        :disabled="loading"
      >
        <v-window v-model="current_window">
          <v-window-item :value="1">
            <v-card-title>
              <v-img
                src="@/assets/images/313.jpg"
                min-width="300"
                width="30vw"
                max-width="auto"
              ></v-img>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col>
                  <p>
                    Halaman ini merupakan halaman verifikasi bagi member kedas
                    beauty yang telah melakukan registrasi. Silahkan klik/tap
                    tombol dibawah ini untuk melakukan verifikasi.
                  </p>
                  <v-alert
                    v-if="message"
                    prominent
                    dense
                    type="info"
                    icon="mdi-information-variant"
                    class="mb-0"
                  >
                    Kuota Verifikasi Telah Penuh. Sesi Verifikasi Akan Dibuka
                    Kembali Setiap Hari Senin
                  </v-alert>

                  <v-btn
                    dark
                    large
                    depressed
                    color="indigo"
                    @click="handleWindow(1)"
                    v-if="!message"
                  >
                    <span>Mulai</span>
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    dark
                    large
                    depressed
                    color="indigo"
                    @click="handleWindow(1)"
                  >
                    <span>Mulai</span>
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="2">
            <v-card-title> Pilih Sesi Anda </v-card-title>
            <v-card-text>
              <v-form ref="session">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      label="Sesi"
                      :items="session"
                      item-text="date"
                      item-value="id"
                      :loading="loading"
                      :rules="form.rules.session"
                      v-model="form.data.session_id"
                      @change="checkSession"
                    ></v-autocomplete>
                    <!-- <v-autocomplete
                      label="Sesi"
                      :items="session"
                      item-text="date"
                      item-value="id"
                      :loading="loading"
                      v-model="form.data.session_id"
                      @change="checkSession"
                    ></v-autocomplete> -->
                  </v-col>
                  <v-col cols="12" md="6" v-if="form.data.session_id">
                    <v-autocomplete
                      label="Pilih Customer Service"
                      :items="agent.list"
                      item-text="name"
                      item-value="id"
                      v-model="agent.id"
                      :loading="loading"
                      :rules="form.rules.agent"
                      :disabled="form.data.session_id == null || alert.session == true"
                      @change="checkAgent"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          icon
                          @click="handleWindow(2)"
                          :disabled="alert.session || alert.agent"
                        >
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </template>
                    </v-autocomplete>
                    <!-- <v-btn
                    large
                    depressed
                    color="indigo"
                    @click="handleWindow(2)"
                    :disabled="form.data.session_id == null || alert.show == true"
                  >
                    <span class="white--text">lanjutkan</span>
                    <v-icon color="white" right>mdi-arrow-right</v-icon>
                  </v-btn> -->
                    <!-- <v-btn
                      large
                      depressed
                      color="indigo"
                      @click="handleWindow(2)"
                    >
                      <span class="white--text">lanjutkan</span>
                      <v-icon color="white" right>mdi-arrow-right</v-icon>
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-form>
              <v-alert
                prominent
                dense
                dark
                icon="mdi-information-variant"
                color="info"
                class="mb-0"
                v-if="alert.show"
              >
                {{ alert.text }}
              </v-alert>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="3">
            <v-card-title>
              From Data Diri
              <v-spacer></v-spacer>
              <v-avatar color="indigo accent-2">
                <v-icon color="white">mdi-card-account-details-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              <v-form ref="member">
                <v-autocomplete
                  label="Website ID"
                  :items="username.data"
                  item-text="username"
                  item-value="username"
                  v-model="username.value"
                  :loading="website.loading"
                  @change="fetchData('self')"
                  :rules="form.rules.web_id"
                ></v-autocomplete>

                <v-expand-transition>
                  <div v-show="!form.isSubmited">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Nama"
                          v-model="form.data.name"
                          :rules="form.rules.name"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="No. Handphone"
                          v-model="form.data.phone"
                          :rules="form.rules.phone"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-textarea
                      label="Alamat"
                      v-model="form.data.address"
                      :rules="form.rules.address"
                      readonly
                    ></v-textarea>

                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Kota/Kabupaten"
                          v-model="form.data.district"
                          :rules="form.rules.district"
                          readonly
                        ></v-text-field>
                        <v-checkbox
                          label="Luar Negeri"
                          v-model="form.foreign"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          ref="membership"
                          label="Membership"
                          :items="member.status"
                          v-model="form.data.status"
                          :rules="form.rules.status"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-autocomplete
                      label="Upline Website ID"
                      :disabled="form.data.status == 'Reseller' ? false : true"
                      :items="username.data"
                      item-text="username"
                      @change="fetchData('upline')"
                      item-value="username"
                      messages="Kosongkan jika join di pusat"
                      v-model="website.upline.username"
                    ></v-autocomplete>

                    <v-file-input
                      label="Foto KTP"
                      v-model="form.data.id_card_image"
                      show-size
                      accept="image/png, image/jpeg, image/bmp"
                      :rules="form.rules.id_card_image"
                    ></v-file-input>

                    <v-row no-gutters>
                      <v-spacer></v-spacer>
                      <v-btn depressed large color="indigo" @click="saveData">
                        <span class="white--text">submit</span>
                        <v-icon right color="white">mdi-send</v-icon>
                      </v-btn>
                    </v-row>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div v-show="form.isSubmited && username.value !== null">
                    <v-alert
                      prominent
                      color="info"
                      dense
                      icon="mdi-information-variant"
                      class="mb-0"
                      dark
                    >
                      Anda sudah pernah mengisi data diri
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-form>
            </v-card-text>
          </v-window-item>
        </v-window>
      </v-card>
    </v-container>

    <v-dialog v-model="website.upline.show" width="500" persistent>
      <v-card>
        <v-card-title> Data Upline </v-card-title>
        <v-card-text v-if="website.upline.data">
          <v-row>
            <v-col>
              <h4>Nama</h4>
            </v-col>
            <v-col>
              <p>: {{ website.upline.data.nama }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4>No. Handphone</h4>
            </v-col>
            <v-col>
              <p>: {{ website.upline.data.telepon }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4>Alamat</h4>
            </v-col>
            <v-col>
              <p>: {{ website.upline.data.alamat }}</p>
            </v-col>
          </v-row>
          Apakah benar ini upline anda?
          <v-row>
            <v-col>
              <v-btn
                depressed
                block
                color="indigo"
                @click="website.upline.show = false"
              >
                <span class="white--text">ya</span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn text block @click="closeUplineData">
                <span>tidak</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="agent.dialog" width="500" persistent>
      <v-card :loading="agent.loading" :disabled="agent.loading">
        <v-card-title>
          <v-img src="@/assets/images/customer_service.png"></v-img>
        </v-card-title>
        <v-card-text v-if="agent.data">
          <h3>Data kamu telah kami terima.</h3>
          <p>Klik tombol dibawah untuk melanjutkan verifikasi.</p>
          <v-btn
            block
            depressed
            color="indigo accent-2"
            :href="agent.data.link"
          >
            <span class="white--text">Verifikasi Saya</span>
          </v-btn>
          <br />
          <v-alert prominent dense text color="info" icon="mdi-alert-circle">
            Mohon diperhatikan bahwa pesan otomatis yang muncul harus dikirimkan
            kepada customer service kami agar dapat melakukan verifikasi.
            Apabila tidak mengirimkan pesan tersebut maka proses verifikasi anda
            sebelumnya kami nyatakan gugur.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import Compressor from "compressorjs";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      current_window: 1,
      loading: false,
      session: null,
      alert: {
        session: false,
        agent: false,
        show: false,
        type: "info",
        text: null,
      },
      website: {
        loading: false,
        data: null,
        upline: {
          username: null,
          data: null,
          loading: false,
          show: false,
        },
      },
      username: {
        data: null,
        value: null,
      },
      member: {
        data: null,
        status: ["Reseller", "Agen", "Distributor"],
      },
      form: {
        isSubmited: true,
        loading: false,
        foreign: false,
        id_card: null,
        data: {
          name: null,
          phone: null,
          address: null,
          district: null,
          id_card_image: null,
          session_id: null,
          status: null,
        },
        rules: {
          web_id: [(v) => !!v || "Website ID tidak boleh kosong"],
          name: [(v) => !!v || "Nama tidak boleh kosong"],
          phone: [(v) => !!v || "No. Handphone tidak boleh kosong"],
          address: [(v) => !!v || "Alamat tidak boleh kosong"],
          district: [(v) => !!v || "Kota/Kabupaten tidak boleh kosong"],
          status: [(v) => !!v || "Membership tidak boleh kosong"],
          id_card_image: [
            (v) => !!v || "Foto KTP tidak boleh kosong",
            (value) =>
              !value ||
              value.size < 1000000 ||
              "Foto KTP tidak boleh lebih dari 1 MB",
          ],
          agent: [(v) => !!v || "Agent tidak boleh kosong"],
          session: [(v) => !!v || "Session tidak boleh kosong"],
        },
      },
      agent: {
        list: null,
        dialog: false,
        loading: false,
        id: null,
        data: null,
      },
      message: false,
    };
  },
  computed: {
    ...mapGetters(["allSessions"]),
  },
  methods: {
    ...mapActions(["addMemberVerify"]),
    handleWindow(step) {
      step += 1;
      this.current_window = step;
    },
    async getSessions() {
      this.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/verify/sessions`)
        .then((response) => {
          // console.log(response.data);
          if (response.data.length > 0) {
            this.session = response.data;
          } else {
            this.message = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async fetchUsernames() {
      this.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/username`)
        .then((response) => {
          this.loading = false;
          this.username.data = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchData(type) {
      this.website.loading = true;
      let website_id = null;
      // console.log(type);
      if (type == "upline") {
        website_id = this.website.upline.username;
      } else {
        website_id = this.username.value;
        this.checkVerificationData(website_id);
      }
      // console.log(website_id);
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/id/${website_id}`)
        .then((response) => {
          this.website.loading = false;
          let data = response.data[0];
          this.setData(data, type);
          // console.log(data);
        })
        .catch((err) => {
          this.website.loading = false;
          console.log(err);
        });
    },
    setData(data, type) {
      switch (type) {
        case "self":
          this.website.data = data;
          this.form.data.name = data.nama;
          this.form.data.phone = data.telepon;
          this.form.data.address = data.alamat;
          this.form.data.district = data.kota;
          break;
        case "upline":
          this.website.upline.data = data;
          this.website.upline.show = true;
          break;
      }
    },
    saveData() {
      this.loading = true;
      let formData = new FormData();
      let image = this.form.data.id_card_image;
      let isValid = this.$refs.member.validate();
      let region = this.form.foreign ? "foreign" : "domestic";

      console.log(this.form.id_card);
      if (isValid) {
        formData.append("session_id", this.form.data.session_id);
        formData.append("website_id", this.username.value);
        formData.append("name", this.form.data.name);
        formData.append("phone", this.form.data.phone);
        formData.append("address", this.form.data.address);
        formData.append("district", this.form.data.district);
        formData.append("status", this.form.data.status);
        formData.append("region", region);
        formData.append(
          "upline",
          this.website.upline.username == null
            ? "Pusat"
            : this.website.upline.username
        );
        formData.append("agent_id", this.agent.id)

        new Compressor(image, {
          quality: 0.6,
          success: (result) => {
            formData.append("id_card_image", result);
            axios
              .post(
                `${process.env.VUE_APP_API_URL}/api/member/verify`,
                formData
              )
              .then((response) => {
                this.loading = false;
                this.agent.data = response.data.agent;
                this.agent.dialog = true;
                console.log(response.data);
              })
              .catch((err) => {
                console.log(err);
              });
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
    },
    closeUplineData() {
      this.website.upline.show = false;
      this.website.upline.username = null;
    },
    async fetchAgent(id) {
      this.loading = true;
      // this.agent.loading = true;
      // let id = this.agent.id;
      if (id) {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/api/agents/${id}`)
          .then((response) => {
            // this.agent.loading = false;
            this.loading = false;
            this.agent.data = response.data;
            console.log(this.agent.data);
          })
          .catch((err) => {
            // this.agent.loading = false;
            this.loading = false;
            console.log(err);
          });
      }
    },
    async checkSession() {
      this.loading = true;
      this.agent.id = null
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/member/verify/sessions/check/${this.form.data.session_id}`
        )
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          if (response.data.status != "open") {
            this.alert.show = true;
            this.alert.session = true
            this.alert.text =
              "Mohon maaf, kuota pada sesi ini telah ditutup. Silahkan pilih sesi lain";
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async fetchAgentList() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/agents/list`)
        .then((response) => {
          // console.log(response.data);
          this.agent.list = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkAgent() {
      this.loading = true;
      this.alert.show = false;
      let devide_by = this.agent.list.length;

      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/member/check/${this.form.data.session_id}/${this.agent.id}/${devide_by}`
        )
        .then((response) => {
          this.loading = false;
          this.fetchAgent(this.agent.id);
          console.log(response.status);
        })
        .catch((err) => {
          this.loading = false;
          this.alert.show = true;
          this.alert.agent = true
          this.alert.text =
            "Mohon maaf, CS yang anda pilih tidak tersedia, silahkan pilih CS lain";
          console.log(err.status);
        });
    },
    async checkVerificationData(id) {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/verify/${id}`)
        .then((response) => {
          console.log(response.data);
          if (response.data) {
            this.form.isSubmited = true;
          } else {
            this.form.isSubmited = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.form.isSubmited = false;
        });
    },
  },
  watch: {
    current_window(val) {
      if (val == 3) {
        this.fetchUsernames();
      }
    },
  },
  mounted() {
    this.getSessions();
    this.fetchAgentList();
  },
};
</script>